* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-family: 'Poppins', sans-serif;
}

code {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
}


#root {
  height: 100%;
}
.vjs-record .vjs-record-button.vjs-control {
    color: #eb5050;
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
}
.vjs-record .vjs-record-button.vjs-control.vjs-icon-record-start:before {
    font-size: 30px !important;
    line-height: 0px !important;
}
.vjs-record .vjs-record-button.vjs-control.vjs-icon-record-stop:before {
    font-size: 30px !important;
    background-color: #989a9e;
    border-radius: 50%;
    line-height: 0px !important;
}
.vjs-control-bar {
    background-color: rgba(255, 255, 255, 0) !important;
}
.vjs-duration,
.vjs-time-divider {
    display: none !important;
}
.vjs-current-time .vjs-time-control .vjs-control {
    position: absolute;

    left: 5% !important;
    top: -100px;
}
.vjs-current-time {
    position: absolute;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    transform: translate(-50%, 0) !important;
    left: 50% !important;
    top: -80px;
    color: #eb5050;
}
.vjs-play-control .vjs-control .vjs-button .vjs-paused {
    display: none !important;
}
.vjs-record .vjs-play-control.vjs-control {
    width: 0px !important;
    height: 0px !important;
}
.vjs-icon-placeholder::before {
    display: none !important;
}